import { THREE } from './three'

export const material = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  flatShading: true
})

export const concrete = new THREE.MeshLambertMaterial({
  color: 0xa3a3a3,
  emissive: 0x50505
})

export const textMaterial = new THREE.MeshLambertMaterial({
  color: 0xb2b2b2,
  emissive: 0x000000,
  fog: false
})

export const sphereMaterial = new THREE.MeshPhongMaterial({
  color: 0xffffff,
  flatShading: true
})

export const createMirrorMaterial = (
  mirrorCamera: THREE.CubeCamera,
  isGlossy = false
) => {
  if (isGlossy) {
    return new THREE.MeshBasicMaterial({
      envMap: mirrorCamera.renderTarget.texture,
      flatShading: true
    })
  } else {
    return new THREE.MeshStandardMaterial({
      envMap: mirrorCamera.renderTarget.texture,
      flatShading: true
    })
  }
}
