import { THREE } from './three'
let STLLoader = require('three-stl-loader')(THREE)
let loader = new STLLoader()
import {
  BUILDING_RECEIVE_SHADOW,
  LOAD_CHUNK_EVERY,
  BUILDING_PER_CHUNK,
  CHUNK_LOADING_OFFSET,
  MAX_CHUNK_LOADED
} from './constsants'
import { loadText } from './load'

let chunkLoaded = [5]

export const buildings = [
  'models/building1.stl',
  'models/building3.stl',
  'models/building4.stl',
  'models/building5.stl',
  'models/building6.stl'
]

export const stlLoad = path => {
  return new Promise((resolve, reject) => {
    loader.load(path, model => resolve(model))
  })
}

export const loadGeometries = async (list: string[]) => {
  const geometries: THREE.Geometry[] = []
  for (const item of list) {
    loadText('Loading: ' + item)
    let geo = (await stlLoad(item)) as THREE.Geometry
    loadText('DONE')
    geometries.push(geo)
  }
  return geometries
}

export const generateCity = async (
  ammountOfBuildings: number,
  geometries: object[],
  material,
  reverse: Boolean = false
) => {
  let group = new THREE.Group()
  let buildingY = 0
  let offsetZ = 9
  let mesh, geo
  let buildingOffset = 3
  for (let i = 0; i < ammountOfBuildings; i++) {
    // get a random building from the list
    geo = geometries[Math.floor(Math.random() * geometries.length)]
    await geo.computeBoundingBox()
    mesh = new THREE.Mesh(geo, material)
    let length = -geo.boundingBox.min.z
    let posY = buildingY + length + buildingOffset
    let posX = (Math.random() - 0.5) * 2
    mesh.position.z = buildingY + length
    mesh.position.x = posX
    if (reverse) mesh.scale.x = -1
    buildingY = posY
    mesh.castShadow = true
    mesh.receiveShadow = BUILDING_RECEIVE_SHADOW
    group.add(mesh)
  }
  return group
}

export const loadCityChunk = async (
  chunk: number,
  geometries,
  material,
  length: number,
  offset: number
) => {
  let group = new THREE.Group()
  let left = await generateCity(length, geometries, material, true)
  left.position.x = 5
  left.position.z = offset
  left.castShadow = true
  left.name = `left`
  group.add(left)
  let right = await generateCity(length, geometries, material)
  right.position.x = -5
  right.position.z = offset
  right.castShadow = true
  right.name = `right`
  group.add(right)
  group.name = `chunk-${chunk}`
  return group
}

export const handleChunk = async (
  posZ: number,
  geometries,
  material,
  scene
) => {
  chunkLoaded.sort((a, b) => a - b)
  const lastChunk = chunkLoaded[chunkLoaded.length - 1]
  if (posZ + LOAD_CHUNK_EVERY > lastChunk) {
    chunkLoaded.push(lastChunk + LOAD_CHUNK_EVERY)
    let chunk = await loadCityChunk(
      lastChunk,
      geometries,
      material,
      BUILDING_PER_CHUNK,
      lastChunk + CHUNK_LOADING_OFFSET
    )
    scene.add(chunk)
    // keep the array to
    if (chunkLoaded.length > MAX_CHUNK_LOADED) {
      const newChunkLoaded = chunkLoaded.slice(
        chunkLoaded.length - MAX_CHUNK_LOADED,
        chunkLoaded.length
      )
      let difference = chunkLoaded.filter(x => !newChunkLoaded.includes(x))
      let oldChunk = scene.getObjectByName(`chunk-${difference[0]}`)
      if (oldChunk) scene.remove(oldChunk)
      chunkLoaded = newChunkLoaded
    }
  }
}
