import { THREE } from './three'
import { TextGeometryParameters } from 'three'
var loader = new THREE.FontLoader()

export const loadFont = fontPath => {
  return new Promise(resolve => {
    loader.load(fontPath, font => resolve(font))
  })
}

export const createText = async (
  font,
  text: string,
  material,
  size: number,
  depth: number
): Promise<THREE.Mesh> => {
  let textParams: TextGeometryParameters = {
    font,
    size,
    height: depth,
    curveSegments: 16,
    bevelEnabled: false,
    bevelThickness: 2,
    bevelSize: 2,
    bevelOffset: 2,
    bevelSegments: 5
  }
  // @ts-ignore
  let textGeo = new THREE.TextGeometry(text, textParams)
  textGeo.center()
  let textMesh = new THREE.Mesh(textGeo, material)
  return textMesh
}
