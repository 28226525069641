import { PositionalAudio, AudioListener, Audio } from 'three'
import { AudioAnalyser, Object3D } from 'three'

const initPlayer = (container, songElement) => {
  const player = container.querySelector('.song-player')
  const play = player.querySelector('.play')
  const pause = player.querySelector('.pause')

  play.addEventListener('click', () => {
    songElement.play()
  })
  pause.addEventListener('click', () => {
    songElement.pause()
  })
}

export const initAudio = (container: HTMLElement, id, camera) => {
  const listener = new AudioListener()
  camera.add(listener)

  const sound = new Audio(listener)
  const songElement = document.getElementById(id) as HTMLMediaElement
  songElement.volume = 0.7

  sound.setMediaElementSource(songElement)
  songElement.play()
  container.classList.add('is-playing')
  initPlayer(container, songElement)

  return sound
}

export const initPositionalAudio = (container: HTMLElement, id, camera) => {
  const listener = new AudioListener()
  camera.add(listener)

  let sound = new PositionalAudio(listener)
  const songElement = document.getElementById(id) as HTMLMediaElement
  songElement.volume = 0.7

  sound.setMediaElementSource(songElement)
  sound.setRefDistance(20)

  songElement.play()
  container.classList.add('is-playing')
  initPlayer(container, songElement)

  return sound
}

export const updateAudio = (
  container,
  sound,
  analyser: AudioAnalyser,
  ui: Object3D,
  camera
) => {
  let element = sound.source.mediaElement
  if (element.paused) {
    container.classList.remove('is-playing')
  } else {
    container.classList.add('is-playing')
    camera.position.z += 0.5

    analyser.getAverageFrequency()

    let snareVolume = (analyser.data[7] + analyser.data[8]) / 2 / 256
    const snareScale = 1 + snareVolume / 20

    ui.scale.set(snareScale, snareScale, snareScale)
  }
}
