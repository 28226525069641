export const BACKGROUND_COLOR = 0x000000
export const FOV = 80
export const KEYBOARD_UP = [38, 87]
export const KEYBOARD_DOWN = [40, 83]
export const KEYBOARD_LEFT = [37, 65]
export const KEYBOARD_RIGHT = [39, 68]
export const CONTROLS = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}
export const SPHERE_DEFINITION = 24
export const SHADOW_QUALITY = 1024
export const BUILDING_RECEIVE_SHADOW = true

// Building generator
export const LOAD_CHUNK_EVERY = 35
export const MAX_CHUNK_LOADED = 5
export const BUILDING_PER_CHUNK = 4
export const CHUNK_LOADING_OFFSET = 50
