import * as THREE from 'three'
import {
  KEYBOARD_UP,
  KEYBOARD_DOWN,
  KEYBOARD_LEFT,
  KEYBOARD_RIGHT,
  CONTROLS
} from './constsants'

export const controls = new Set()

const ANGLE = 0.55
const ANGLEH = 0.4

// Controls
document.onkeydown = function(evt: KeyboardEvent) {
  handleKey(KEYBOARD_UP, evt, CONTROLS['UP'])
  handleKey(KEYBOARD_DOWN, evt, CONTROLS['DOWN'])
  handleKey(KEYBOARD_LEFT, evt, CONTROLS['LEFT'])
  handleKey(KEYBOARD_RIGHT, evt, CONTROLS['RIGHT'])
}

const clickControls = document.querySelectorAll('.controls') as NodeListOf<
  HTMLElement
>
clickControls.forEach(control => {
  const direction =
    (control.dataset.direction as 'UP') || 'DOWN' || 'LEFT' || 'RIGHT'

  control.addEventListener('pointerdown', evt => {
    controls.add(CONTROLS[direction])
    preventDefault(evt)
  })
  control.addEventListener('pointerup', evt => {
    controls.delete(CONTROLS[direction])
    preventDefault(evt)
  })
})

const handleKey = (keys: number[], evt: KeyboardEvent, controlName: string) => {
  if (keys.includes(evt.keyCode)) {
    controls.add(controlName)
    preventDefault(evt)
  }
}

const preventDefault = evt => {
  evt.preventDefault()
  return null
}

document.onkeyup = function(evt: KeyboardEvent) {
  if (KEYBOARD_UP.includes(evt.keyCode)) {
    controls.delete(CONTROLS['UP'])
  }
  if (KEYBOARD_DOWN.includes(evt.keyCode)) {
    controls.delete(CONTROLS['DOWN'])
  }
  if (KEYBOARD_LEFT.includes(evt.keyCode)) {
    controls.delete(CONTROLS['LEFT'])
  }
  if (KEYBOARD_RIGHT.includes(evt.keyCode)) {
    controls.delete(CONTROLS['RIGHT'])
  }
}

document.addEventListener('touchend', (evt: TouchEvent) => {
  console.log(evt)
})

// Pointer support
let isPointerDown = false
document.addEventListener('pointerdown', event => {
  isPointerDown = true
})
document.addEventListener('pointerup', event => {
  isPointerDown = false
})

export const isBeingPressed = (key: String) => {
  return controls.has(key)
}

// Called from the loop
export const updateControls = (camera, renderer) => {
  camera.rotation.z = 180 * THREE.Math.DEG2RAD

  if (isBeingPressed(CONTROLS['UP'])) {
    if (camera.rotation.x > 2.5 || camera.rotation.x < 0)
      camera.rotateX(2 * THREE.Math.DEG2RAD)
  }
  if (isBeingPressed(CONTROLS['DOWN'])) {
    if (camera.rotation.x < -2.5 || camera.rotation.x > 0)
      camera.rotateX(-2 * THREE.Math.DEG2RAD)
  }
  if (isBeingPressed(CONTROLS['LEFT'])) {
    if (camera.rotation.y > -ANGLE) camera.rotateY(2 * THREE.Math.DEG2RAD)
  } else if (isBeingPressed(CONTROLS['RIGHT'])) {
    if (camera.rotation.y < ANGLE) camera.rotateY(-2 * THREE.Math.DEG2RAD)
  } else {
    camera.position.x = 0
  }
}
