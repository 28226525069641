// Imports
import { THREE } from './three'
import { concrete } from './materials'
import { createLights } from './lights'
import { buildings, loadGeometries, loadCityChunk } from './buildings'
import { createUI } from './ui'
import { BACKGROUND_COLOR } from './constsants'

export const initScene = async () => {
  const scene: THREE.Scene = new THREE.Scene()

  var STLLoader = require('three-stl-loader')(THREE)

  var loader = new STLLoader()

  // Load floor
  let floor: THREE.Mesh
  loader.load('models/floor.stl', function(geometry) {
    const floor = new THREE.Mesh(geometry, concrete)
    floor.scale.z = 100
    floor.receiveShadow = true
    floor.name = 'floor'
    scene.add(floor)
  })

  // Load building
  let geometries = await loadGeometries(buildings)
  let firstChunk = await loadCityChunk(0, geometries, concrete, 10, 0)
  scene.add(firstChunk)

  // particles
  // const particles = await initParticles(scene)
  // scene.add(particles)

  // Scene
  scene.background = new THREE.Color(BACKGROUND_COLOR)

  // fog
  const fog = new THREE.Fog(BACKGROUND_COLOR, 10, 60)
  scene.fog = fog

  return { geometries, scene, fog }
}
