// import { THREE, Stats } from './three'
import * as THREE from 'three'
import { initScene } from './initScene'
import { createCamera } from './camera'
import { handleChunk } from './buildings'
import { concrete, createMirrorMaterial, textMaterial } from './materials'
import { updateLight, createLights } from './lights'
import { updateUI, createMirrorCamera, createUI } from './ui'
import { updateControls } from './controls'
import { createRender } from './renderer'
// import { Stats } from './three'
import { handleResize } from './handleResize'
import { initAudio, initPositionalAudio, updateAudio } from './audio'

const initPage = async () => {
  let container = document.querySelector('.scene') as HTMLElement
  container.classList.add('is-loading')

  let { scene, geometries, fog } = await initScene()

  // Camera
  let camera = createCamera(container)
  camera.position.y = 4
  camera.updateProjectionMatrix()
  camera.rotateY(180 * THREE.Math.DEG2RAD)

  // renderer
  let renderer = createRender(container, scene, camera)

  const discoBallCamera = createMirrorCamera(2)
  scene.add(discoBallCamera)
  const discoBallMaterial = createMirrorMaterial(discoBallCamera, false)

  // ui
  let ui = await createUI(discoBallMaterial, textMaterial)
  scene.add(ui)
  ui.castShadow = true

  const discoBall = ui.getObjectByName('sphere')

  // Lights
  const lights = createLights(ui.getObjectByName('sphere'))
  scene.add(lights)

  // animation
  function updateMesh() {
    // Move floor with camera
    let floor = scene.getObjectByName('floor')
    floor.position.z = camera.position.z - 5
    // camera.lookAt(floor.position)
    // camera.quaternion.copy(floor.quaternion)
  }

  function updateRender() {
    discoBallCamera.position.z = camera.position.z + 4
    discoBallCamera.update(renderer, scene)
    renderer.render(scene, camera)
  }

  // Audio
  let sound = initPositionalAudio(container, 'song', camera)
  let analyser = new THREE.AudioAnalyser(sound, 32)
  discoBall.add(sound)

  let timestamp = 0
  renderer.setAnimationLoop(() => {
    handleChunk(camera.position.z, geometries, concrete, scene)
    updateMesh()
    updateRender()
    updateControls(camera, renderer)
    updateLight(scene, camera, fog)
    updateUI(ui, camera, timestamp, scene)
    updateAudio(container, sound, analyser, scene.getObjectByName('UI'), camera)

    timestamp++
    // stats.update()
  })

  // debug

  // STATS
  // let stats = new Stats()
  // stats.domElement.style.position = 'absolute'
  // stats.domElement.style.bottom = '0px'
  // stats.domElement.style.zIndex = '100'
  // container.appendChild(stats.domElement)

  // @ts-ignore
  window.camera = camera
  // @ts-ignore
  window.scene = scene

  container.classList.remove('is-loading')
  container.classList.add('is-loaded')

  // Resize handling
  window.addEventListener(
    'resize',
    () => {
      handleResize(renderer, camera)
    },
    false
  )

  const buttons = document.querySelectorAll('.openModal')
  console.log(buttons)

  buttons.forEach(button => {
    button.addEventListener('click', event => {
      console.log('WUT')

      const modal = document.querySelector(
        `#${button.dataset.modal}`
      ) as HTMLDialogElement
      modal.showModal()
    })
  }, false)

  const closeButtons = document.querySelectorAll('.closeModal')

  closeButtons.forEach(button => {
    button.addEventListener('click', event => {
      const modal = event.target.closest('dialog')
      modal.close()
    })
  })
}

function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

docReady(function() {
  let button = document.querySelector('.loadScene')
  button.removeAttribute('disabled')
  button.addEventListener('click', initPage)
})
