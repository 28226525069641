import { THREE } from './three'
import { createText, loadFont } from './text'
import { ThisExpression } from 'typescript'

export const createMirrorCamera = height => {
  const camera = new THREE.CubeCamera(0.1, 100, 96)
  camera.position.set(0, height, 0)
  return camera
}

export const createUI = async (
  ballMaterial: THREE.Material,
  textMaterial: THREE.Material
) => {
  let UI = new THREE.Group()
  // Line 1
  const font = (await loadFont('./Gruppo_Regular.json')) as THREE.Font
  let line1 = await createText(font, 'I Hate', textMaterial, 0.7, 0.15)
  let line2 = await createText(font, 'Dancing', textMaterial, 0.7, 0.15)
  line1.name = 'line1'
  line2.name = 'line2'
  line1.position.y = 5
  line2.position.y = 4
  // line1.castShadow = true
  line1.receiveShadow = true
  line1.rotateY(180 * THREE.Math.DEG2RAD)
  line2.rotateY(180 * THREE.Math.DEG2RAD)
  UI.add(line1)
  UI.add(line2)
  // Disco ball
  let sphereGeom = new THREE.SphereGeometry(1, 12, 12) // radius, segmentsWidth, segmentsHeight
  let mirrorSphere = new THREE.Mesh(sphereGeom, ballMaterial)
  mirrorSphere.position.y = 2.4
  // mirrorSphere.rotateZ(THREE.Math.DEG2RAD * 90)
  mirrorSphere.name = 'sphere'
  // mirrorSphere.castShadow = true
  mirrorSphere.receiveShadow = true
  UI.add(mirrorSphere)

  UI.name = 'UI'
  return UI
}

export const updateUI = (ui, camera, timestamp, scene: THREE.Scene) => {
  if (!ui) return
  ui.position.z = camera.position.z + 6
  ui.getObjectByName('sphere')?.rotateY(-0.03)
}
