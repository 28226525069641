import { THREE } from './three'
import { FOV, SHADOW_QUALITY } from './constsants'

export const createLights = (ui: THREE.Object3D) => {
  const lights = new THREE.Group()
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.4)
  ambientLight.position.setY(30)
  ambientLight.position.setX(5)
  ambientLight.name = 'light-ambient'
  lights.add(ambientLight)

  const colorLight = new THREE.AmbientLight(0xffffff, 1.1)
  colorLight.name = 'light-color'
  lights.add(colorLight)
  const pointLight = new THREE.PointLight(0xffffff, 0.4, 30)
  pointLight.name = 'light-point'
  lights.add(pointLight)
  return lights
}

export const updateLight = (
  scene: THREE.Scene,
  camera: THREE.Camera,
  fog: THREE.Fog
) => {
  // Move light
  let pointLight = scene.getObjectByName('light-point')
  if (pointLight) pointLight.position.z = camera.position.z
  // Vary light
  let ambiantLight = scene.getObjectByName('light-color') as THREE.Light
  let hueProgression = (camera.position.z / 2) % 357
  let lightColor = new THREE.Color(`hsl(${hueProgression}, 50%, 40%)`)
  let backgroundColor = new THREE.Color(`hsl(${hueProgression}, 30%, 20%)`)
  const root = document.querySelector(':root') as HTMLBaseElement
  root.style.setProperty('--overlay-text-color', lightColor.getStyle())
  if (ambiantLight) ambiantLight.color = lightColor
  fog.color = backgroundColor
  scene.background = backgroundColor
  const spot1 = scene.getObjectByName('light-spot1')
  if (spot1) spot1.position.z = camera.position.z
  const spot2 = scene.getObjectByName('light-spot2')
  if (spot2) spot2.position.z = camera.position.z
}
